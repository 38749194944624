/* eslint-disable import/no-anonymous-default-export */
/**
 * Abilities Reducer
 */
//action types
import {
  FETCHED_ACCOUNT_DETAILS,
  FETCHED_USER_PERMISSIONS,
  UPDATED_USER_PROFILE_DETAILS,
  RELOAD_ACCOUNT_DETAILS_PAYWALL_STATE,
  HIDE_INVITATION_CODE_BLOCKER,
  LOGOUT_USER,
  CONNECT_TWITTER,
  DISCONNECT_TWITTER,
} from "actions/Types";
import { MEMBERSHIP_LEVELS } from "constants/membership.consts";

// Add import
import { AccountDetails } from "kre8tv/model";

const INITIAL_STATE = {
  did_complete_onboarding: undefined,
  account_details: localStorage.getItem("account_details")
    ? new AccountDetails(JSON.parse(localStorage.getItem("account_details")))
    : null,
  last_updated: null,
  resource_permission_scopes: [], //all of the user's resource permissions
  should_show_pricing_onboarding_flow: false,
  is_on_personal_plan: false,
  is_subscriber_on_current_plan: false, //used to know if we should show/hide the 'upgrade' button
  is_subscriber_on_personal_plan: false, //specifically for the personal plan
  a_b_testing_group: null,
  twitterConnected: false,
  membership_plan_level: "none", //silver, gold, or none
};

export default (state = INITIAL_STATE, action) => {
  // console.log("AccountDetailsReducer action = ", action);
  switch (action.type) {
    case FETCHED_ACCOUNT_DETAILS: {
      const accountDetails = new AccountDetails(action.payload);
      localStorage.setItem("account_details", JSON.stringify(accountDetails));
      const should_show_pricing_onboarding_flow =
        shouldShowPricingOnboardingFlow(accountDetails);
      const is_on_personal_plan = isOnPersonalPlan(accountDetails);
      const is_subscriber_on_current_plan =
        isSubscribedOnCurrentPlan(accountDetails);
      const is_subscriber_on_personal_plan =
        isSubscribedOnPersonalPlan(accountDetails);
      const show_invitation_code_blocker =
        needShowWaitlistBlocker(accountDetails);
      const membership_plan_level =
        getMembershipTierOnPersonalPlan(accountDetails);
      // console.log("membership_plan_level", membership_plan_level);
      // console.log("accountDetails", accountDetails);
      return {
        ...state,
        did_complete_onboarding:
          accountDetails.completed_onboarding_at !== undefined,
        account_details: accountDetails,
        last_updated: new Date(),
        should_show_pricing_onboarding_flow:
          should_show_pricing_onboarding_flow && !show_invitation_code_blocker,
        is_on_personal_plan: is_on_personal_plan,
        is_subscriber_on_current_plan: is_subscriber_on_current_plan,
        is_subscriber_on_personal_plan: is_subscriber_on_personal_plan,
        a_b_testing_group: isUserIdInTestGroup(accountDetails.id)
          ? "test_1"
          : null,
        show_invitation_code_blocker: show_invitation_code_blocker,
        membership_plan_level: membership_plan_level,
      };
    }
    case FETCHED_USER_PERMISSIONS:
      return {
        ...state,
        resource_permission_scopes: action.payload,
      };
    case UPDATED_USER_PROFILE_DETAILS: {
      const { target_audience } = action.payload.accountDetails;
      const accountDetails = state.account_details;
      accountDetails.target_audience = target_audience;
      return {
        ...state,
        account_details: accountDetails,
      };
    }
    case RELOAD_ACCOUNT_DETAILS_PAYWALL_STATE: {
      const accountDetails = state.account_details;
      const should_show_pricing_onboarding_flow =
        shouldShowPricingOnboardingFlow(accountDetails);
      const is_subscriber_on_current_plan =
        isSubscribedOnCurrentPlan(accountDetails);
      const is_subscriber_on_personal_plan =
        isSubscribedOnPersonalPlan(accountDetails);
      const is_on_personal_plan = isOnPersonalPlan(accountDetails);
      const show_invitation_code_blocker =
        needShowWaitlistBlocker(accountDetails);
      return {
        ...state,
        should_show_pricing_onboarding_flow:
          should_show_pricing_onboarding_flow,
        is_subscriber_on_current_plan: is_subscriber_on_current_plan,
        is_on_personal_plan: is_on_personal_plan,
        is_subscriber_on_personal_plan: is_subscriber_on_personal_plan,
        show_invitation_code_blocker: show_invitation_code_blocker,
      };
    }
    case HIDE_INVITATION_CODE_BLOCKER:
      return {
        ...state,
        show_invitation_code_blocker: false,
      };
    case LOGOUT_USER: {
      localStorage.removeItem("account_details");
      return {
        ...state,
        account_details: null,
      };
    }
    case CONNECT_TWITTER:
      return { ...state, twitterConnected: true };
    case DISCONNECT_TWITTER:
      return { ...state, twitterConnected: false };
    default:
      return { ...state };
  }
};

/**
 * Determines if the pricing onboarding flow should be shown to the user based on their account details.
 *
 * @param {Object} accountDetails - The details of the user's account.
 * @returns {boolean} - True if the pricing onboarding flow should be shown, false otherwise.
 */
/**
 * Determines if the pricing onboarding flow should be shown to the user based on their account details.
 *
 * @param {Object} accountDetails - The details of the user's account.
 * @returns {boolean} - True if the pricing onboarding flow should be shown, false otherwise.
 */
const shouldShowPricingOnboardingFlow = (accountDetails) => {
  // Make sure we have account details.
  if (!accountDetails || !accountDetails.id) return false;

  // Calculate the epoch time for 30 seconds ago.
  // const thirtySecondsAgo = Date.now() - 30000;

  // Calculate 1 day ago
  // const oneDayAgo = Date.now() - 86400000;
  // make it 1708986739000
  const start = 1708986739000;

  // Convert the account creation date from the account details to epoch time.
  const accountCreatedEpoch = new Date(accountDetails.created_on).getTime();

  // Determine if the account is new by comparing the account creation epoch to thirty seconds ago.
  // const isAccountNew = accountCreatedEpoch > thirtySecondsAgo;
  // const isAccountNew = accountCreatedEpoch > start;

  // also determine if the account is older than 3 days
  // const threeDays = 3 * 24 * 60 * 60 * 1000;
  // const isAccountOldEnough = accountCreatedEpoch < Date.now() - threeDays;

  // new - always true
  const isAccountOldEnough = true;

  // const seconds_since_creation = (Date.now() - accountCreatedEpoch) / 1000;
  // console.log("account created ", seconds_since_creation, " seconds ago");

  // Check if the account is a personal account based on the presence of an organization ID.
  const personalPlan = isOnPersonalPlan(accountDetails);

  // New - check if the user is subscribed to the personal plan
  const isSubscriberOnPersonalPlan = isSubscribedOnPersonalPlan(accountDetails);

  // Determine if the account is active for the current mode (personal or organization).
  // For personal accounts, check if the membership tier is not "none".
  // For organization accounts, check if the membership tier for the current organization is not "none".

  // either personal plan, or not owner of current org, or they are owner and have a paid plan
  // const isActiveForCurrentMode =
  //   (personalPlan && isSubscriberOnPersonalPlan) ||
  //   !isOwnerOfCurrentOrg(accountDetails) ||
  //   accountDetails.purchased_membership_tier_stripe !== "none";

  let isActiveForCurrentMode = false;
  if (personalPlan) {
    isActiveForCurrentMode = isSubscriberOnPersonalPlan;
  } else {
    const isOwner = isOwnerOfCurrentOrg(accountDetails);
    //if they are not the owner, but are on a paid plan, then appear as subscribed, since we only show the upgrade button to the owner (who can pay)
    if (isOwner) {
      isActiveForCurrentMode =
        accountDetails.purchased_membership_tier_stripe !== "none";
    } else {
      isActiveForCurrentMode = true;
    }
  }

  // The pricing onboarding flow should be shown if the account is not new and not active for the current mode.
  // Note: Temporarily never show for a team plan
  // const shouldShow = !isAccountNew && !isActiveForCurrentMode && personalPlan;

  // const shouldShow = !isAccountNew && !isActiveForCurrentMode;

  // now just show for if not active for current mode
  const shouldShow = !isActiveForCurrentMode && isAccountOldEnough;

  return shouldShow;
};
const isOnPersonalPlan = (accountDetails) => {
  if (!accountDetails) return true;

  return !(
    accountDetails.organization_details &&
    accountDetails.organization_details.id
  );
};

// Used to determine if the we hide/show the 'upgrade' button
const isSubscribedOnCurrentPlan = (accountDetails) => {
  if (!accountDetails) return false;

  const isOnPersonal = isOnPersonalPlan(accountDetails);

  if (isOnPersonal) {
    return isSubscribedOnPersonalPlan(accountDetails);
  } else {
    const isOwner = isOwnerOfCurrentOrg(accountDetails);
    //if they are not the owner, but are on a paid plan, then appear as subscribed, since we only show the upgrade button to the owner (who can pay)
    if (!isOwner) return true;
    return accountDetails.purchased_membership_tier_stripe !== "none";
  }
};

export const getMembershipTierOnPersonalPlan = (accountDetails) => {
  if (!accountDetails) return MEMBERSHIP_LEVELS.NONE;
  const membership_tier = accountDetails.membership_tier;

  switch (membership_tier) {
    case "premium":
      return MEMBERSHIP_LEVELS.PREMIUM;
    case "premium_gold":
      return MEMBERSHIP_LEVELS.PREMIUM_GOLD;
    default:
      return MEMBERSHIP_LEVELS.NONE;
  }
};

/**
 * Used to determine if the user is subscribed to the personal plan.
 *
 * @param {Object} accountDetails - The details of the user's account.
 * @returns {boolean} - True if the user is subscribed to the personal plan, false otherwise.
 */
const isSubscribedOnPersonalPlan = (accountDetails) => {
  if (!accountDetails) return false;

  // base / old - check membership tier
  const is_subscribed = accountDetails.membership_tier !== "none";
  const is_in_test_group = isUserIdInTestGroup(accountDetails.id);

  if (is_subscribed) return true;

  // new - check specific stripe plan
  const is_subscribed_stripe =
    accountDetails.isSubscribedToProIndividualStripe();
  if (is_subscribed_stripe) return true;

  // for now - let's only show the upgrade button to the test group (so we return false)
  // return is_in_test_group === false;

  return false;
};

const isOwnerOfCurrentOrg = (accountDetails) => {
  const user_id = accountDetails.id;
  return accountDetails.organization_details?.user_id === user_id;
};

const needShowWaitlistBlocker = (accountDetails) => {
  if (!accountDetails) return false;

  //1. if their account was created before 19th july, 2024
  const created_on = new Date(accountDetails.created_on);
  const july_19_2024 = new Date("2024-07-19");
  const created_before_july_19_2024 = created_on < july_19_2024;
  if (created_before_july_19_2024) return false;

  //2. if they are in test group, don't show the blocker
  // const is_in_test_group = isUserIdInTestGroup(accountDetails.id);
  // if (is_in_test_group) return false;

  const has_beta_access = accountDetails.has_beta_access;

  //4. check there has_beta_access flag
  if (has_beta_access) return false;

  //3. if they are on personal account, but not paying
  const is_on_personal_plan = isOnPersonalPlan(accountDetails);
  if (is_on_personal_plan) {
    const is_subscribed_on_personal_plan =
      isSubscribedOnPersonalPlan(accountDetails);
    if (!is_subscribed_on_personal_plan) return true;
  }

  //4. if non of the above, show the blocker
  return true;
};

//based on the id, let's hash the string and ge ta number, and make it so there is a 10% chance of being in the test group
const isUserIdInTestGroup = (userId) => {
  return true; // to force yes for everyone
  // return false; // to force no for everyone
  // const hash = userId.split("").reduce(function (a, b) {
  //   a = (a << 5) - a + b.charCodeAt(0);
  //   return a & a;
  // }, 0);
  // // %10 = 10% chance
  // // %100 = 1% chance
  // // %1000 = 0.1% chance
  // // %3 = 33% chance
  // // %2 = 50% chance
  // // return hash % 10 === 0;

  // return hash % 10 === 0;
};
