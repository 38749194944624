import {
  MINI_APP_SAVE_SETTINGS,
  MINI_APP_LOAD_SETTINGS,
  MINI_APP_CLEAR_SETTINGS,
  MINI_APP_SAVE_RESULTS,
  MINI_APP_LOAD_RESULTS,
  MINI_APP_CLEAR_RESULTS,
} from "actions/Types";

/**
 * Initial mini apps state
 */
const INIT_STATE = {
  // Store settings by mini app ID
  settings: JSON.parse(localStorage.getItem("mini_app_settings")) || {},
  // Store results by mini app ID
  results: JSON.parse(localStorage.getItem("mini_app_results")) || {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MINI_APP_SAVE_SETTINGS:
      const updatedSettings = {
        ...state.settings,
        [action.payload.miniAppId]: action.payload.settings,
      };
      localStorage.setItem(
        "mini_app_settings",
        JSON.stringify(updatedSettings)
      );
      return {
        ...state,
        settings: updatedSettings,
      };

    case MINI_APP_LOAD_SETTINGS:
      return {
        ...state,
        settings:
          JSON.parse(localStorage.getItem("mini_app_settings")) ||
          state.settings,
      };

    case MINI_APP_CLEAR_SETTINGS:
      const filteredSettings = { ...state.settings };
      delete filteredSettings[action.payload.miniAppId];
      localStorage.setItem(
        "mini_app_settings",
        JSON.stringify(filteredSettings)
      );
      return {
        ...state,
        settings: filteredSettings,
      };

    case MINI_APP_SAVE_RESULTS:
      const updatedResults = {
        ...state.results,
        [action.payload.miniAppId]: action.payload.results,
      };
      localStorage.setItem("mini_app_results", JSON.stringify(updatedResults));
      return {
        ...state,
        results: updatedResults,
      };

    case MINI_APP_LOAD_RESULTS:
      return {
        ...state,
        results:
          JSON.parse(localStorage.getItem("mini_app_results")) || state.results,
      };

    case MINI_APP_CLEAR_RESULTS:
      const filteredResults = { ...state.results };
      delete filteredResults[action.payload.miniAppId];
      localStorage.setItem("mini_app_results", JSON.stringify(filteredResults));
      return {
        ...state,
        results: filteredResults,
      };

    default:
      return { ...state };
  }
};
