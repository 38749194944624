import {
  MINI_APP_SAVE_SETTINGS,
  MINI_APP_LOAD_SETTINGS,
  MINI_APP_CLEAR_SETTINGS,
  MINI_APP_SAVE_RESULTS,
  MINI_APP_LOAD_RESULTS,
  MINI_APP_CLEAR_RESULTS,
} from "./Types";

/**
 * Save mini app settings
 * @param {string} miniAppId - Unique identifier for the mini app
 * @param {object} settings - Settings to save
 */
export const saveMiniAppSettings = (miniAppId, settings) => ({
  type: MINI_APP_SAVE_SETTINGS,
  payload: { miniAppId, settings },
});

/**
 * Load mini app settings
 */
export const loadMiniAppSettings = () => ({
  type: MINI_APP_LOAD_SETTINGS,
});

/**
 * Clear mini app settings
 * @param {string} miniAppId - Unique identifier for the mini app
 */
export const clearMiniAppSettings = (miniAppId) => ({
  type: MINI_APP_CLEAR_SETTINGS,
  payload: { miniAppId },
});

/**
 * Save mini app results
 * @param {string} miniAppId - Unique identifier for the mini app
 * @param {array} results - Results to save
 */
export const saveMiniAppResults = (miniAppId, results) => ({
  type: MINI_APP_SAVE_RESULTS,
  payload: { miniAppId, results },
});

/**
 * Load mini app results
 */
export const loadMiniAppResults = () => ({
  type: MINI_APP_LOAD_RESULTS,
});

/**
 * Clear mini app results
 * @param {string} miniAppId - Unique identifier for the mini app
 */
export const clearMiniAppResults = (miniAppId) => ({
  type: MINI_APP_CLEAR_RESULTS,
  payload: { miniAppId },
});
