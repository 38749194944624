import { IEntity } from "./interfaces/entity.interface";
import { RecipeIngredient } from "./VLRecipeIngredient";

export class CreationResult implements IEntity {
  /**
   * Creation id (primary key)
   */
  id = "";
  /**
   * Last updated by user id
   */
  last_updated_by_user_id: string = "";
  /**
   * Input type
   */
  input: AbilityInputType[] = ["text"];
  /**
   * Output type
   */
  output: AbilityInputType[] = ["text"];
  /**
   * The ability id (superpower)
   */
  superpower_id = ""; //superpower id
  /**
   * The title of the ability (superpower)
   */
  superpower_title = ""; //superpower title
  /**
   * Recipe id
   */
  recipe_id?: string;
  /**
   * Raw user input
   */
  raw_input = "";
  /**
   * Last updated date
   */
  last_updated: number = 0;
  /**
   * Created on date
   */
  created_on: number = 0;
  /**
   * user id of who ran it
   */
  user_id = "";
  /**
   * id of sequence
   */
  sequence_id?: string;

  /**
   * Creation result (text, file url, etc)
   */
  result?: any;

  /**
   * SVG Icon Name (material ui)
   */
  icon?: string;

  /**
   * Recipe ingredients
   */
  ingredients?: RecipeIngredient[];

  /**
   * JSON parameters for the creation
   */
  params_json?: any;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }

    this.id = obj.id;

    this.user_id = obj.user_id;
    this.result = obj.result;

    //input + output
    this.input = obj.input;
    this.output = obj.output;

    //details
    this.superpower_id = obj.superpower_id;
    this.superpower_title = obj.superpower_title;
    this.recipe_id = obj.recipe_id;
    this.sequence_id = obj.sequence_id;
    this.params_json = obj.params_json;

    //metadata
    const now = new Date().getTime();
    this.created_on = obj.created_on ?? now;
    this.last_updated = obj.last_updated ?? now;
    this.last_updated_by_user_id = obj.user_id;
    this.icon = obj.icon;

    this.ingredients = (obj.ingredients ?? []).map(
      (i: any) => new RecipeIngredient(i)
    );
  }

  createdOnString(): string {
    let date = new Date(this.created_on);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  lastUpdatedString(): string {
    let date = new Date(this.last_updated);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }
}
