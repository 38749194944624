/**
 * Membership level constants
 * Ensures consistent naming across the application
 */
export const MEMBERSHIP_LEVELS = {
  NONE: "none",
  PREMIUM: "premium",
  PREMIUM_GOLD: "premium_gold",
};

/**
 * Maps backend membership values to display labels
 */
export const MEMBERSHIP_LABELS = {
  [MEMBERSHIP_LEVELS.NONE]: "Free Plan",
  [MEMBERSHIP_LEVELS.PREMIUM]: "Pro Silver",
  [MEMBERSHIP_LEVELS.PREMIUM_GOLD]: "Pro Gold",
};

/**
 * Maps backend membership values to display colors
 */
export const MEMBERSHIP_COLORS = {
  [MEMBERSHIP_LEVELS.NONE]: "inherit",
  [MEMBERSHIP_LEVELS.PREMIUM]: "silver",
  [MEMBERSHIP_LEVELS.PREMIUM_GOLD]: "gold",
};

/**
 * Converts legacy membership values to standardized ones
 * @param {string} legacyMembership - Old membership value
 * @returns {string} Standardized membership value
 */
export const convertLegacyMembership = (legacyMembership: string) => {
  if (!legacyMembership) return MEMBERSHIP_LEVELS.NONE;

  switch (legacyMembership.toLowerCase()) {
    case "pro_silver":
      return MEMBERSHIP_LEVELS.PREMIUM;
    case "pro_gold":
      return MEMBERSHIP_LEVELS.PREMIUM_GOLD;
    case "free":
      return MEMBERSHIP_LEVELS.NONE;
    default:
      return legacyMembership;
  }
};
