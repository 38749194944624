/* eslint-disable import/no-anonymous-default-export */
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import {
  REUSE_RESULT,
  CLEAR_RESULT_TO_REUSE,
  SET_SELECTED_RESULT,
  CLEAR_SELECTED_RESULT,
  LOAD_RESULTS,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAILURE,
  REFRESH_RESULTS,
  REFRESH_RESULTS_SUCCESS,
  REFRESH_RESULTS_FAILURE,
  LOAD_MORE_RESULTS,
  LOAD_MORE_RESULTS_SUCCESS,
  LOAD_MORE_RESULTS_FAILURE,
  ADD_PENDING_RESULT,
  REMOVE_PENDING_RESULT,
  CLEAR_PENDING_RESULTS,
  TOGGLE_FAVORITE,
  ADD_NEW_RESULTS,
  SET_RESULT_BEING_EDITED,
  CLEAR_RESULT_BEING_EDITED,
  SET_ACTIVE_SUPERPOWER,
  SET_SUPERPOWER_ACCESS,
  CLEAR_SUPERPOWER_ACCESS,
  LOGOUT_USER,
  DELETE_RESULT,
} from "actions/Types";

// Helper functions for localStorage
const saveActivePowerId = (id) => {
  try {
    console.log("[SuperpowersReducer] Saving active superpower ID:", id);
    if (id) {
      localStorage.setItem("activeSuperpower", id);
    } else {
      localStorage.removeItem("activeSuperpower");
    }
  } catch (error) {
    console.error(
      "[SuperpowersReducer] Error saving active superpower ID:",
      error
    );
  }
};

const getActivePowerId = () => {
  try {
    const savedId = localStorage.getItem("activeSuperpower");
    console.log(
      "[SuperpowersReducer] Retrieved active superpower ID from localStorage:",
      savedId
    );
    return savedId || "restyle_upscale_image"; // Default to Upstyle if nothing is saved
  } catch (error) {
    console.error(
      "[SuperpowersReducer] Error loading active superpower ID:",
      error
    );
    return "restyle_upscale_image"; // Default to Upstyle if there's an error
  }
};

// Helper functions for superpower access
const saveSuperpowerAccess = (accessState) => {
  try {
    console.log(
      "[SuperpowersReducer] Saving superpower access state:",
      accessState
    );
    localStorage.setItem("superpowerAccess", JSON.stringify(accessState));
  } catch (error) {
    console.error(
      "[SuperpowersReducer] Error saving superpower access state:",
      error
    );
  }
};

const getSuperpowerAccess = () => {
  try {
    const savedAccess = localStorage.getItem("superpowerAccess");
    if (savedAccess) {
      const parsedAccess = JSON.parse(savedAccess);
      console.log(
        "[SuperpowersReducer] Retrieved superpower access state from localStorage:",
        parsedAccess
      );
      return parsedAccess;
    }
    return {}; // Default to empty object if nothing is saved
  } catch (error) {
    console.error(
      "[SuperpowersReducer] Error loading superpower access state:",
      error
    );
    return {}; // Default to empty object if there's an error
  }
};

// Load the active superpower ID from localStorage during initialization
const savedActivePowerId = getActivePowerId();
console.log(
  "[SuperpowersReducer] Initial active superpower ID:",
  savedActivePowerId
);

// Load the superpower access state from localStorage during initialization
const savedSuperpowerAccess = getSuperpowerAccess();
console.log(
  "[SuperpowersReducer] Initial superpower access state:",
  savedSuperpowerAccess
);

const INITIAL_STATE = {
  selectedResult: null,
  resultToReuse: null,
  resultBeingEdited: null,
  results: [],
  pendingResults: [],
  didLoadOnceSuccessfully: false,
  isLoading: false,
  isLoadingMore: false,
  hasMore: false,
  error: null,
  activeSuperpower: savedActivePowerId,
  superpowerAccess: savedSuperpowerAccess,
};

// Add logout helper function
const clearUserDefaults = () => {
  try {
    console.log("[SuperpowersReducer] Clearing user defaults for logout");
    localStorage.removeItem("activeSuperpower");
    localStorage.removeItem("superpowerAccess");
  } catch (error) {
    console.error("[SuperpowersReducer] Error clearing user defaults:", error);
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_SUPERPOWER:
      console.log(
        "[SuperpowersReducer] Setting active superpower:",
        action.payload
      );
      saveActivePowerId(action.payload);
      return {
        ...state,
        activeSuperpower: action.payload,
      };

    case REUSE_RESULT:
      console.log("Superpowers Reducer: REUSE_RESULT", action.payload);
      return {
        ...state,
        resultToReuse: action.payload,
      };

    case CLEAR_RESULT_TO_REUSE:
      console.log("Superpowers Reducer: CLEAR_RESULT_TO_REUSE");
      return {
        ...state,
        resultToReuse: null,
      };

    case SET_SELECTED_RESULT:
      console.log(
        "[SuperpowersReducer] SET_SELECTED_RESULT called with payload:",
        action.payload
      );
      return {
        ...state,
        selectedResult: action.payload,
      };

    case CLEAR_SELECTED_RESULT:
      console.log("[SuperpowersReducer] CLEAR_SELECTED_RESULT called");
      return {
        ...state,
        selectedResult: null,
      };

    case LOAD_RESULTS:
      // console.log("Reducer: LOAD_RESULTS");
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case LOAD_RESULTS_SUCCESS:
      // console.log("Reducer: LOAD_RESULTS_SUCCESS", action.payload);
      return {
        ...state,
        isLoading: false,
        results: action.payload.results,
        hasMore: action.payload.hasMore,
        error: null,
        didLoadOnceSuccessfully: true,
      };

    case LOAD_RESULTS_FAILURE:
      // console.log("Reducer: LOAD_RESULTS_FAILURE", action.payload);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case REFRESH_RESULTS:
      // console.log("Reducer: REFRESH_RESULTS");
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case REFRESH_RESULTS_SUCCESS:
      // console.log("Reducer: REFRESH_RESULTS_SUCCESS", action.payload);
      return {
        ...state,
        isLoading: false,
        results: action.payload.results,
        hasMore: action.payload.hasMore,
        error: null,
        didLoadOnceSuccessfully: true,
      };

    case REFRESH_RESULTS_FAILURE:
      // console.log("Reducer: REFRESH_RESULTS_FAILURE", action.payload);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case LOAD_MORE_RESULTS:
      // console.log("Reducer: LOAD_MORE_RESULTS");
      return {
        ...state,
        isLoadingMore: true,
        error: null,
      };

    case LOAD_MORE_RESULTS_SUCCESS:
      console.log("Reducer: LOAD_MORE_RESULTS_SUCCESS", action.payload);
      return {
        ...state,
        isLoadingMore: false,
        results: [...state.results, ...action.payload.results],
        hasMore: action.payload.hasMore,
        error: null,
      };

    case LOAD_MORE_RESULTS_FAILURE:
      // console.log("Reducer: LOAD_MORE_RESULTS_FAILURE", action.payload);
      return {
        ...state,
        isLoadingMore: false,
        error: action.payload,
      };

    case ADD_PENDING_RESULT:
      // console.log("Reducer: ADD_PENDING_RESULT", action.payload);
      // console.log(
      //   "Reducer: ADD_PENDING_RESULT new size should be",
      //   1 + state.pendingResults.length
      // );
      return {
        ...state,
        pendingResults: [...state.pendingResults, action.payload],
      };

    case REMOVE_PENDING_RESULT:
      // console.log("Reducer: REMOVE_PENDING_RESULT", action.payload);
      return {
        ...state,
        pendingResults: state.pendingResults.filter(
          (result) => !action.payload.includes(result.id)
        ),
      };

    case CLEAR_PENDING_RESULTS:
      // console.log("Reducer: CLEAR_PENDING_RESULTS");
      return {
        ...state,
        pendingResults: [],
      };

    case TOGGLE_FAVORITE:
      // console.log("Reducer: TOGGLE_FAVORITE", action.payload);
      return {
        ...state,
        results: state.results.map((result) =>
          result.id === action.payload.result.id
            ? { ...result, isFavorite: action.payload.isFavorite }
            : result
        ),
      };

    case ADD_NEW_RESULTS:
      // console.log("Reducer: ADD_NEW_RESULTS", action.payload);
      return {
        ...state,
        results: [...state.results, ...action.payload],
      };

    case DELETE_RESULT:
      console.log("Reducer: DELETE_RESULT", action.payload);
      // If the deleted result is the selected result, clear it
      const newState = {
        ...state,
        results: state.results.filter(result => result.id !== action.payload.id),
      };
      
      // If the deleted result is the selected result, clear it
      if (state.selectedResult && state.selectedResult.id === action.payload.id) {
        newState.selectedResult = null;
      }
      
      // If the deleted result is being reused, clear it
      if (state.resultToReuse && state.resultToReuse.id === action.payload.id) {
        newState.resultToReuse = null;
      }
      
      // If the deleted result is being edited, clear it
      if (state.resultBeingEdited && state.resultBeingEdited.id === action.payload.id) {
        newState.resultBeingEdited = null;
      }
      
      return newState;

    case SET_RESULT_BEING_EDITED:
      console.log("Reducer: SET_RESULT_BEING_EDITED", action.payload);
      return {
        ...state,
        resultBeingEdited: action.payload,
      };

    case CLEAR_RESULT_BEING_EDITED:
      console.log("Reducer: CLEAR_RESULT_BEING_EDITED");
      return {
        ...state,
        resultBeingEdited: null,
      };

    case SET_SUPERPOWER_ACCESS:
      console.log("Reducer: SET_SUPERPOWER_ACCESS", action.payload);
      const newAccessState = {
        ...state.superpowerAccess,
        ...action.payload,
      };
      saveSuperpowerAccess(newAccessState);
      return {
        ...state,
        superpowerAccess: newAccessState,
      };

    case CLEAR_SUPERPOWER_ACCESS:
      console.log("Reducer: CLEAR_SUPERPOWER_ACCESS");
      saveSuperpowerAccess({});
      return {
        ...state,
        superpowerAccess: {},
      };

    case LOGOUT_USER:
      console.log("[SuperpowersReducer] Processing logout");
      clearUserDefaults();
      return {
        ...INITIAL_STATE,
        activeSuperpower: "restyle_upscale_image", // Reset to default
        superpowerAccess: {},
      };

    default:
      return state;
  }
};
