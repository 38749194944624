/**
 * Hulk - A Material Design Admin Template
 * Copyright 2020 All Rights Reserved
 * Made Wih Love
 * Created By The Iron Network, LLC
 */
import { analytics } from "analytics/analytics";
import React from "react";
import ReactDOM from "react-dom";
import { isProduction } from "util/SCUtils";
//intercom
import "./config/intercom";
import * as Sentry from "@sentry/react";
import { API_BASE_URL } from "constants/app.consts";

//init sentry
Sentry.init({
  dsn: isProduction()
    ? "https://09f9af5446694816be1206f83570b144@o4504531345866752.ingest.sentry.io/4505561500024832"
    : "https://b2f9618a3c6840d1afd4c594920c5940@o4504531345866752.ingest.sentry.io/4505561428787200",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", API_BASE_URL],
    }),
    new Sentry.Replay(),
    Sentry.feedbackIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: isProduction() ? 0.2 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: isProduction() ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Save a reference to the root element for reuse
const rootEl = document.getElementById("root");

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require("./App").default;
  ReactDOM.render(<MainApp />, rootEl);
};

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(<NextApp />, rootEl);
  });
}
analytics.init(isProduction());
render();
