/**
 * Auth Actions
 */

import firebase from "@firebase/app";

// jwt
import { userService } from "../_services";

//utils
import * as SCUtils from "../util/SCUtils";

import { NotificationManager } from "react-notifications";
import "@firebase/auth";
//Action types
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  FETCHED_USER_DETAILS,
  SIGNUP_USER,
  LOGIN_EMAIL_CHANGED,
  LOGIN_PASSWORD_CHANGED,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGOUT_USER,
  JWT_LOGIN_REQUEST,
  JWT_LOGIN_SUCCESS,
  JWT_LOGIN_FAILURE,
  LOGIN_USER_MAGIC_LINK_SENT,
  FETCHED_AUTHED_USER_DETAILS,
  UPDATED_AUTHED_USER_DETAILS,
  FETCHING_CURRENT_USER_DETAILS,
  FIREBASE_AUTH_STATUS_CHANGED,
  SHOW_AUTH_MODAL,
  SHOW_AUTH_MODAL_FOR_DELETE_USER,
  HIDE_AUTH_MODAL,
  SHOW_ACCOUNT_MODAL,
  HIDE_ACCOUNT_MODAL,
  SHOW_PRICING_MODAL,
  HIDE_PRICING_MODAL,
  SHOW_PURCHASING_ONBOARDING_FLOW,
  HIDE_PURCHASING_ONBOARDING_FLOW,
  SHOW_SCRIPT_SELECTOR_MODAL,
  HIDE_SCRIPT_SELECTOR_MODAL,
} from "./Types";
import {
  deleteAccount,
  fetchCurrentUserDetails,
  updateUserProfileDetails,
} from "services/users.service";
import { store } from "store";
import {
  fetchCurrentUserCreations,
  fetchCurrentUserFavoritedCreations,
} from "./CreationActions";
import {
  addUserAccountDetailsListener,
  fetchCurrentUserAccountDetails,
  removeUserAccountDetailsListener,
  fetchCurrentUserResourcePermissions,
} from "./AccountDetailsActions";
import {
  getInspirationSources,
  getInspirationSourceItems,
} from "./InspirationSourceActions";
import { analytics } from "analytics/analytics";
import { UserManager } from "kre8tv/managers";
import {
  fetchUserOrganizations,
  updateAccountMembershipStatusFromWebApp,
} from "./OrganizationActions";

//Central manager of authentication auth or logout state

firebase.auth().onAuthStateChanged(async (user) => {
  /* Dispatch methods accordingly to user's state */
  if (user) {
    localStorage.setItem("user_id", user.uid);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("email", user.email);
    onAuthStatusChanged(user);
    initializeCurrentUserSession();
    //Authed
  } else {
    onAuthStatusChanged(null);
    //Not Authed
    localStorage.removeItem("user_id");
    localStorage.removeItem("user");
    localStorage.removeItem("user_details");
  }
});

const onAuthStatusChanged = (user) => {
  const isLoggedIn = user ? true : false;
  store.dispatch({ type: FIREBASE_AUTH_STATUS_CHANGED, payload: isLoggedIn });
};
/**
 * This function is called when the user is logged in
 * It fetches the user details and dispatches the action to update the user details
 * It also fetches the user's creations and favorited creations
 * It also fetches the user's account details
 * It also fetches the user's resource permissions
 * It also adds a listener to the user's account details
 * It also adds a listener to the user's resource permissions
 */
export const initializeCurrentUserSession = async () => {
  // console.log("initializeCurrentUserSession called");
  try {
    store.dispatch({
      type: FETCHING_CURRENT_USER_DETAILS,
    });
    const currentUserDetails = await fetchCurrentUserDetails();

    if (currentUserDetails.user) {
      //important - make sure to save the user details to local storage
      localStorage.setItem(
        "user_details",
        JSON.stringify(currentUserDetails.user)
      );
    }
    store.dispatch({
      type: FETCHED_AUTHED_USER_DETAILS,
      payload: {
        user: currentUserDetails.user,
        socialAccounts: currentUserDetails.socialAccounts,
      },
    });
    //now fetch creations and the likes
    // fetchCurrentUserCreations();
    // fetchCurrentUserFavoritedCreations();
    store.dispatch(addUserAccountDetailsListener());
    store.dispatch(addUserDetailsListener());
    store.dispatch(fetchCurrentUserAccountDetails());
    store.dispatch(fetchCurrentUserResourcePermissions());
    store.dispatch(fetchUserOrganizations());
    store.dispatch(updateAccountMembershipStatusFromWebApp());
    //fetch current user account details

    return currentUserDetails;
  } catch (error) {
    NotificationManager.error(error.message);
  }
};

export function updateCurrentUserDetails(
  firstName,
  lastName,
  email,
  silent = false
) {
  return async (dispatch) => {
    try {
      const updatedDetails = await updateUserProfileDetails(
        firstName,
        lastName,
        email
      );
      dispatch({
        type: UPDATED_AUTHED_USER_DETAILS,
        payload: updatedDetails.user,
      });
      if (!silent) NotificationManager.success("Account info updated");
      return { success: true, result: updatedDetails, error: null };
    } catch (error) {
      NotificationManager.error(error.message);
      return { success: false, result: null, error: error.message };
    }
  };
}

export const signupUserWithJwt = (user, history) => (dispatch) => {
  // return dispatch => {
  dispatch({ type: JWT_LOGIN_REQUEST, payload: user });

  userService.signup(user.email, user.password).then(
    (user) => {
      dispatch({ type: JWT_LOGIN_SUCCESS, payload: user });
      history.push("/");
      NotificationManager.success("Account connected");
    },
    (error) => {
      dispatch({ type: JWT_LOGIN_FAILURE, payload: error });
      // dispatch(alertActions.error(error));
    }
  );
};

export const signinUserWithJwt = (user, history) => (dispatch) => {
  // return dispatch => {
  dispatch({ type: JWT_LOGIN_REQUEST, payload: user });

  userService.login(user.email, user.password).then(
    (user) => {
      dispatch({ type: JWT_LOGIN_SUCCESS, payload: user });
      history.push("/");
      NotificationManager.success("User Logged In Successfully");
    },
    (error) => {
      dispatch({ type: JWT_LOGIN_FAILURE, payload: error });
      // dispatch(alertActions.error(error));
    }
  );
};
export const refreshToken = (history) => (dispatch) => {
  userService.refreshToken().then(
    (user) => {
      // console.log("user", user);
    },
    (error) => {
      console.error("refreshToken error", error);
      localStorage.removeItem("user_id");
    }
  );
};
/**
 * Function to signin using firebase
 */
export const signinUserWithFirebase = (user, history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  firebase
    .auth()
    .signInWithEmailAndPassword(user.email, user.password)
    .then((user) => {
      localStorage.setItem("user_id", JSON.stringify(user.user.uid));
      loginUserSuccess(dispatch, localStorage.getItem("user_id"), history);
    })
    .catch((error) => {
      console.error("error", error);
      loginUserFailure(dispatch, error);
    });
};

/**
 * Function to signin using firebase email link
 */
export const sendUserFirebaseEmailLink =
  (user, history, redirectToURL, forDeletingUser = false) =>
  (dispatch) => {
    dispatch({ type: LOGIN_USER });

    const redirectToLink =
      forDeletingUser === false ? redirectToURL ?? null : null;
    const base_url = SCUtils.baseAppURL();
    const route = SCUtils.ROUTES.FINISH_CONNECT;
    const url = `${base_url}${route}`;

    //save user email to local storage
    localStorage.setItem("emailForSignIn", user.email);
    //save redirect url to local storage
    localStorage.setItem("signup_redirect_url", redirectToLink);

    if (forDeletingUser) {
      localStorage.setItem("delete_user_after_auth", true);
    } else {
      localStorage.removeItem("delete_user_after_auth");
    }

    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: url,
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: SCUtils.baseAppIdentifieriOS(),
      },
      android: {
        packageName: SCUtils.baseAppIdentifierAndroid(),
        installApp: true,
        minimumVersion: "12",
      },
      dynamicLinkDomain: SCUtils.baseAuthDeepLinking(),
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(user.email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        loginUserEmailLinkSent(dispatch, user.email, history);
      })
      .catch((error) => {
        console.error("error sending email link:", error);
        loginUserFailure(dispatch, error);
      });
  };

/**
 * Function to create firebase account
 * @param {*} dispatch
 * @param {*} user
 * @param {*} history
 */
export const signupUserWithFirebase = (user, history) => (dispatch) => {
  dispatch({ type: SIGNUP_USER });
  firebase
    .auth()
    .createUserWithEmailAndPassword(user.email, user.password)
    .then((user) => {
      localStorage.setItem("user_id", user.user.uid);
      signupUserSuccess(dispatch, localStorage.getItem("user_id"), history);
    })
    .catch((error) => {
      console.error(error);
      signupUserFailure(dispatch, error);
    });
};

export const showAuth = () => (dispatch) => {
  dispatch({ type: SHOW_AUTH_MODAL });
};

export const hideAuth = () => (dispatch) => {
  dispatch({ type: HIDE_AUTH_MODAL });
};

export const showAccount = () => (dispatch) => {
  const url = new URL(window.location.href);
  url.pathname = "/settings"; // Set the pathname to '/settings'
  window.history.pushState({}, "", url.toString()); // Push the updated URL with the same query parameters
  dispatch({ type: SHOW_ACCOUNT_MODAL });
};

export const hideAccount = () => (dispatch) => {
  //remove show_account=true to url
  const url = new URL(window.location.href);
  // url.searchParams.delete("show_account");
  window.history.pushState({}, "", url);
  dispatch({ type: HIDE_ACCOUNT_MODAL });
};

export const showPricing = () => (dispatch) => {
  dispatch({ type: SHOW_PRICING_MODAL, payload: { pricing_mode: "team" } });
};

export const showPricingIndividual = () => (dispatch) => {
  dispatch({
    type: SHOW_PRICING_MODAL,
    payload: { pricing_mode: "individual" },
  });
};

export const hidePricing = () => (dispatch) => {
  dispatch({ type: HIDE_PRICING_MODAL });
};

export const showPurchasingOnboardingFlow =
  (
    showFreeTrial = true,
    autoLoadPersonalPlan = false,
    autoLoadTeamPlan = false,
    customPaymentSuccessUrl = undefined,
    customPaymentCancelUrl = undefined
  ) =>
  (dispatch) => {
    dispatch({
      type: SHOW_PURCHASING_ONBOARDING_FLOW,
      payload: {
        show_free_trial: showFreeTrial,
        auto_load_personal_plan: autoLoadPersonalPlan,
        auto_load_team_plan: autoLoadTeamPlan,
        custom_payment_success_url: customPaymentSuccessUrl,
        custom_payment_cancel_url: customPaymentCancelUrl,
      },
    });
  };

export const hidePurchasingOnboardingFlow = () => (dispatch) => {
  dispatch({ type: HIDE_PURCHASING_ONBOARDING_FLOW });
};

export const showScriptSelectorModal = (mode) => (dispatch) => {
  dispatch({ type: SHOW_SCRIPT_SELECTOR_MODAL, payload: { mode } });
};

export const hideScriptSelectorModal = () => (dispatch) => {
  dispatch({ type: HIDE_SCRIPT_SELECTOR_MODAL });
};

/**
 * Function to create firebase account
 * @param {*} dispatch
 * @param {*} user
 * @param {*} history
 */
export const confirmUserFirebaseEmailLink = (history) => (dispatch) => {
  // Confirm the link is a sign-in with email link.
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem("emailForSignIn");
    if (!email || email.length === 0) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    analytics.logEvent("web - flow - sign in - will confirm email link", {
      email: email,
    });
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then((user) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        analytics.logEvent("web - flow - sign in - did confirm email link");
        localStorage.setItem("user_id", user.uid);
        localStorage.setItem("user", JSON.stringify(user));

        //get signup_redirect_url from localstorage
        const redirectToLink = localStorage.getItem("signup_redirect_url");
        analytics.logEvent("web - flow - sign in - success", {
          method: "email",
          redirectPath: redirectToLink ?? "none",
        });

        //attmept to get delete user after auth from localstorage
        const delete_user_after_auth = localStorage.getItem(
          "delete_user_after_auth"
        );

        if (delete_user_after_auth) {
          signupUserSuccess(dispatch, user, history, null, true);
        } else {
          signupUserSuccess(dispatch, user, history, redirectToLink);
        }
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.error(error);
        signupUserFailure(dispatch, error);
      });
  }
};

/**
 * Function to check Login user success
 */
function loginUserSuccess(dispatch, user, history) {
  history.push("/dashboard/dashboard1");
  NotificationManager.success("User Logged In");
}
/**
 * Function to check Login user success
 */
function loginUserEmailLinkSent(dispatch, email, history) {
  dispatch({
    type: LOGIN_USER_MAGIC_LINK_SENT,
    payload: email,
  });
  // history.push("/confirm-email");
  NotificationManager.success(`Sign in email sent`);
}

/**
 * Function to get Login user failure
 */
function loginUserFailure(dispatch, error) {
  dispatch({
    type: LOGIN_USER_FAILURE,
    payload: error,
  });
  NotificationManager.error(error.message);
}

/**
 * Signup user success
 */
function signupUserSuccess(
  dispatch,
  user,
  history,
  redirectPath,
  deletingUser = false
) {
  dispatch({
    type: SIGNUP_USER_SUCCESS,
    payload: user,
  });

  let redirect = redirectPath && redirectPath.length > 0;
  //strip the host from the redirect path
  if (redirect) {
    redirectPath = redirectPath.replace(window.location.origin, "");
  } else {
    redirectPath = null;
  }

  if (deletingUser) {
    //delete user - so redirect to delete user by pushing to deleteAccount
    history.push("/deleteAccount");
  } else {
    //redirect to the redirect path
    history.push(redirectPath ?? "/home");
    NotificationManager.success("Account connected");
  }
}

/**
 * Signup user failure
 */
function signupUserFailure(dispatch, error) {
  dispatch({
    type: SIGNUP_USER_FAILURE,
    payload: error,
  });
  NotificationManager.error(error.message);
}

/**
 * Function to detect email changes
 */
export function onEmailChanged(email) {
  return {
    type: LOGIN_EMAIL_CHANGED,
    payload: email,
  };
}

/**
 * Function to detect password change
 */
export function onPasswordChanged(password) {
  return {
    type: LOGIN_PASSWORD_CHANGED,
    payload: password,
  };
}

/**
 * Redux Action To Signin User In Firebase With Facebook
 */
export const signinUserWithFacebook = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.FacebookAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      // console.log("signinUserWithFacebook result:", result);
    })
    .catch(function (error) {
      console.error("signinUserWithFacebook error:", error);
    });
};

/**
 * Redux Action To Signin User In Firebase With Google
 */
export const signinUserWithGoogle =
  (history, forDeletingUser) => (dispatch) => {
    if (forDeletingUser) {
      localStorage.setItem("delete_user_after_auth", true);
    } else {
      localStorage.removeItem("delete_user_after_auth");
    }

    dispatch({ type: LOGIN_USER });
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        analytics.logEvent("web - flow - sign in - success", {
          method: "google",
        });

        // get user from result
        const user = result.user;

        localStorage.setItem("user_id", user.uid);
        localStorage.setItem("user", JSON.stringify(user));

        //attmept to get delete user after auth from localstorage
        const delete_user_after_auth = localStorage.getItem(
          "delete_user_after_auth"
        );
        if (delete_user_after_auth) {
          signupUserSuccess(dispatch, user, history, null, true);
        } else {
          //get signup_redirect_url from localstorage
          const redirectToLink = localStorage.getItem("signup_redirect_url");
          signupUserSuccess(dispatch, user, history, redirectToLink);
          dispatch(hideAuth());
        }
      })
      .catch(function (error) {
        console.error("signinUserWithGoogle error:", error);
        dispatch({ type: LOGIN_USER_FAILURE, payload: error });
        NotificationManager.error(error.message);
      });
  };

/*
 * Redux Action To Signin User In Firebase With Apple
 */
export const signinUserWithApple = (history, forDeletingUser) => (dispatch) => {
  if (forDeletingUser) {
    localStorage.setItem("delete_user_after_auth", true);
  } else {
    localStorage.removeItem("delete_user_after_auth");
  }

  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.OAuthProvider("apple.com");
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      analytics.logEvent("web - flow - sign in - success", {
        method: "apple",
      });

      // get user from result
      const user = result.user;

      localStorage.setItem("user_id", user.uid);
      localStorage.setItem("user", JSON.stringify(user));

      //attmept to get delete user after auth from localstorage
      const delete_user_after_auth = localStorage.getItem(
        "delete_user_after_auth"
      );
      if (delete_user_after_auth) {
        signupUserSuccess(dispatch, user, history, null, true);
      } else {
        //get signup_redirect_url from localstorage
        const redirectToLink = localStorage.getItem("signup_redirect_url");
        signupUserSuccess(dispatch, user, history, redirectToLink);
        dispatch(hideAuth());
      }
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE, payload: error });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Github
 */
export const signinUserWithGithub = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.GithubAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      localStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: localStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success("User Login Successfully");
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Micosoft/Outlook
 */
export const signinUserWithMicrosoft =
  (history, forDeletingUser) => (dispatch) => {
    if (forDeletingUser) {
      localStorage.setItem("delete_user_after_auth", true);
    } else {
      localStorage.removeItem("delete_user_after_auth");
    }

    dispatch({ type: LOGIN_USER });
    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        analytics.logEvent("web - flow - sign in - success", {
          method: "microsoft",
        });

        // get user from result
        const user = result.user;

        localStorage.setItem("user_id", user.uid);
        localStorage.setItem("user", JSON.stringify(user));

        //attmept to get delete user after auth from localstorage
        const delete_user_after_auth = localStorage.getItem(
          "delete_user_after_auth"
        );
        if (delete_user_after_auth) {
          signupUserSuccess(dispatch, user, history, null, true);
        } else {
          //get signup_redirect_url from localstorage
          const redirectToLink = localStorage.getItem("signup_redirect_url");
          signupUserSuccess(dispatch, user, history, redirectToLink);
          dispatch(hideAuth());
        }
      })
      .catch(function (error) {
        console.error("signinUserWithMicrosoft error:", error);
        dispatch({ type: LOGIN_USER_FAILURE, payload: error });
        NotificationManager.error(error.message);
      });
  };

/**
 * Redux Action To Signin User In Firebase With Twitter
 */
export const signinUserWithTwitter = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.TwitterAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      localStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: localStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success("User Login Successfully!");
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux action to logout user from firebase
 */
export const hulkLogoutUserFirebase = () => (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: LOGOUT_USER });
      localStorage.removeItem("user_id");
      NotificationManager.success("User Logged Out");
    })
    .catch((error) => {
      NotificationManager.error("Firebase logout error::", error);
    });
};

export const logoutUser =
  (from = "unknown") =>
  (dispatch) => {
    analytics.logEvent("web - flow - logout - attempt");

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(removeUserAccountDetailsListener());
        dispatch(removeUserDetailsListener());
        dispatch({ type: LOGOUT_USER, payload: { from } });
        localStorage.removeItem("user_id");
        NotificationManager.success("User Logged Out");
      })
      .catch((error) => {
        NotificationManager.error("Firebase logout error:", error);
      });
  };

export const showAuthForDeleteUser = () => (dispatch) => {
  analytics.logEvent("web - flow - delete account - show auth");
  dispatch({ type: SHOW_AUTH_MODAL_FOR_DELETE_USER });
};

export const deleteUser = () => async (dispatch) => {
  analytics.logEvent("web - flow - delete account - called");

  try {
    //call backend
    const success = await deleteAccount();
    if (success) {
      // console.log("deleteUser BE - success");
      //get firebase user and delete
      const user = firebase.auth().currentUser;
      await user.delete();
      // console.log("deleteUser FB - success");
      dispatch({ type: LOGOUT_USER });
      localStorage.removeItem("user_id");
      NotificationManager.success("User Deleted");
      analytics.logEvent("web - flow - delete account - success");
    }
  } catch (error) {
    NotificationManager.error("Logout error:", error.message);
    analytics.logEvent("web - flow - delete account - error", {
      error: error.message,
    });
  }
};

export const addUserDetailsListener = () => {
  //call listenToCurrentUserDetails from UserManager
  return (dispatch) => {
    // console.log("addUserAccountDetailsListener called");
    try {
      const userManager = new UserManager();
      userManager.listenToCurrentUserDetails((user) => {
        // console.log("user listener response:", user);
        dispatch({
          type: FETCHED_USER_DETAILS,
          payload: user,
        });
      });
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error adding current user details listener"
      );
      return null;
    }
  };
};

export const removeUserDetailsListener = () => {
  //call listenToCurrentUserDetails from UserManager
  return (dispatch) => {
    // console.log("removeUserAccountDetailsListener called");
    try {
      const userManager = new UserManager();
      userManager.removeCurrentUserDetailsListener();
    } catch (error) {
      NotificationManager.error(
        error.message,
        "Error removing user account details listener"
      );
      return null;
    }
  };
};
