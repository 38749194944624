import {
  REUSE_RESULT,
  SET_SELECTED_RESULT,
  CLEAR_SELECTED_RESULT,
  LOAD_RESULTS,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAILURE,
  LOAD_MORE_RESULTS,
  LOAD_MORE_RESULTS_SUCCESS,
  LOAD_MORE_RESULTS_FAILURE,
  ADD_PENDING_RESULT,
  REMOVE_PENDING_RESULT,
  CLEAR_PENDING_RESULTS,
  TOGGLE_FAVORITE,
  SET_RESULTS,
  ADD_NEW_RESULTS,
  CLEAR_RESULT_TO_REUSE,
  REFRESH_RESULTS,
  REFRESH_RESULTS_SUCCESS,
  REFRESH_RESULTS_FAILURE,
  SET_RESULT_BEING_EDITED,
  CLEAR_RESULT_BEING_EDITED,
  SET_ACTIVE_SUPERPOWER,
  SET_SUPERPOWER_ACCESS,
  CLEAR_SUPERPOWER_ACCESS,
  DELETE_RESULT,
} from "actions/Types";

// Import the new action type from the reducer

// Action Creators
/**
 * Action to reuse a result with a flag indicating user intent
 * @param {Object} result - The result to reuse
 */
export const reuseResult = (result) => {
  // console.log("Action Creator: REUSE_RESULT", result);
  return {
    type: REUSE_RESULT,
    payload: {
      ...result,
    },
  };
};

/**
 * Action to set the active superpower ID
 * @param {string} superpowerId - The ID of the superpower to set as active
 */
export const setActiveSuperpower = (superpowerId) => {
  console.log("Action Creator: SET_ACTIVE_SUPERPOWER", superpowerId);
  return {
    type: SET_ACTIVE_SUPERPOWER,
    payload: superpowerId,
  };
};

export const clearResultToReuse = () => {
  // console.log("Action Creator: CLEAR_RESULT_TO_REUSE");
  return {
    type: CLEAR_RESULT_TO_REUSE,
  };
};

export const setSelectedResult = (result) => {
  // console.log("Action Creator: SET_SELECTED_RESULT", result);
  return {
    type: SET_SELECTED_RESULT,
    payload: result,
  };
};

export const clearSelectedResult = () => {
  // console.log("Action Creator: CLEAR_SELECTED_RESULT");
  return {
    type: CLEAR_SELECTED_RESULT,
  };
};

// Results loading actions
export const loadResults = () => {
  // console.log("Action Creator: LOAD_RESULTS");
  return {
    type: LOAD_RESULTS,
  };
};

export const loadResultsSuccess = (results, hasMore) => {
  // console.log("Action Creator: LOAD_RESULTS_SUCCESS", results, hasMore);
  return {
    type: LOAD_RESULTS_SUCCESS,
    payload: { results, hasMore, didLoadOnceSuccessfully: true },
  };
};

export const loadResultsFailure = (error) => {
  // console.log("Action Creator: LOAD_RESULTS_FAILURE", error);
  return {
    type: LOAD_RESULTS_FAILURE,
    payload: error,
  };
};

// Pull to refresh actions
export const refreshResults = () => {
  // console.log("Action Creator: REFRESH_RESULTS");
  return {
    type: REFRESH_RESULTS,
  };
};

export const refreshResultsSuccess = (results, hasMore) => {
  // console.log("Action Creator: REFRESH_RESULTS_SUCCESS", results, hasMore);
  return {
    type: REFRESH_RESULTS_SUCCESS,
    payload: { results, hasMore, didLoadOnceSuccessfully: true },
  };
};

export const refreshResultsFailure = (error) => {
  // console.log("Action Creator: REFRESH_RESULTS_FAILURE", error);
  return {
    type: REFRESH_RESULTS_FAILURE,
    payload: error,
  };
};

export const loadMoreResults = (lastTimestamp) => {
  // console.log("Action Creator: LOAD_MORE_RESULTS", lastTimestamp);
  return {
    type: LOAD_MORE_RESULTS,
    payload: lastTimestamp,
  };
};

export const loadMoreResultsSuccess = (results, hasMore) => {
  // console.log("Action Creator: LOAD_MORE_RESULTS_SUCCESS", results, hasMore);
  return {
    type: LOAD_MORE_RESULTS_SUCCESS,
    payload: { results, hasMore },
  };
};

export const loadMoreResultsFailure = (error) => {
  // console.log("Action Creator: LOAD_MORE_RESULTS_FAILURE", error);
  return {
    type: LOAD_MORE_RESULTS_FAILURE,
    payload: error,
  };
};

// Pending results actions
export const addPendingResult = (result) => {
  // console.log("Action Creator: ADD_PENDING_RESULT", result);
  return {
    type: ADD_PENDING_RESULT,
    payload: result,
  };
};

export const removePendingResult = (resultIds) => {
  // console.log("Action Creator: REMOVE_PENDING_RESULT", resultIds);
  return {
    type: REMOVE_PENDING_RESULT,
    payload: resultIds,
  };
};

export const clearPendingResults = () => {
  // console.log("Action Creator: CLEAR_PENDING_RESULTS");
  return {
    type: CLEAR_PENDING_RESULTS,
  };
};

// Favorite action
export const toggleFavorite = (result, isFavorite) => {
  // console.log("Action Creator: TOGGLE_FAVORITE", result, isFavorite);
  return {
    type: TOGGLE_FAVORITE,
    payload: { result, isFavorite },
  };
};

export const setResults = (results) => {
  // console.log("Action Creator: SET_RESULTS", results);
  return {
    type: SET_RESULTS,
    payload: results,
  };
};

export const addNewResults = (results) => {
  // console.log("Action Creator: ADD_NEW_RESULTS", results);
  return {
    type: ADD_NEW_RESULTS,
    payload: results,
  };
};

/**
 * Action to delete a result
 * @param {Object} result - The result to delete
 */
export const deleteResult = (result) => {
  console.log("Action Creator: DELETE_RESULT", result);
  return {
    type: DELETE_RESULT,
    payload: result,
  };
};

/**
 * Action to set a result as being edited
 * @param {Object} result - The result being edited
 */
export const setResultBeingEdited = (result) => {
  console.log("Action Creator: SET_RESULT_BEING_EDITED", result);
  return {
    type: SET_RESULT_BEING_EDITED,
    payload: result,
  };
};

/**
 * Action to clear the result being edited
 */
export const clearResultBeingEdited = () => {
  console.log("Action Creator: CLEAR_RESULT_BEING_EDITED");
  return {
    type: CLEAR_RESULT_BEING_EDITED,
  };
};

export const setSuperpowerAccess = (accessState) => {
  console.log("Action Creator: SET_SUPERPOWER_ACCESS", accessState);
  return {
    type: SET_SUPERPOWER_ACCESS,
    payload: accessState,
  };
};

export const clearSuperpowerAccess = () => {
  console.log("Action Creator: CLEAR_SUPERPOWER_ACCESS");
  return {
    type: CLEAR_SUPERPOWER_ACCESS,
  };
};
