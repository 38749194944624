import firebase from "firebase";
import {
  AIAbility,
  CreationFavoritedEvent,
  CreationResult,
  RecipeIngredient,
} from "kre8tv/model";
import { Recipe } from "kre8tv/model/VLRecipe";
import {
  createResultFromSuperpower,
  createResultsFromParams,
} from "services/superpowers.service";
import { cleanObject } from "util/SCUtils";
import { DB_CREATION_FAVORITES, DB_CREATIONS } from "./DBConstants";

export class CreationManager {
  base?: any;
  firestore = firebase.firestore();
  constructor() {}

  newDocumentID() {
    return this.firestore.collection(DB_CREATIONS).doc().id;
  }

  async generateResults(
    superpower: AIAbility,
    ingredients: RecipeIngredient[],
    recipe?: Recipe,
    tone?: string,
    numberOfResults?: number
  ) {
    try {
      const createSuperpowerResults = await createResultFromSuperpower(
        superpower,
        ingredients,
        recipe,
        tone,
        numberOfResults
      );

      return { succes: true, results: createSuperpowerResults }; //should be {results : []}
    } catch (error) {
      // throw new Error(error);
      throw error;
    }
  }

  async generateResultsFromParams(
    superpower: AIAbility,
    params: any[],
    recipeId?: string,
    tone?: string,
    numberOfResults?: number
  ) {
    try {
      const createSuperpowerResults = await createResultsFromParams(
        superpower,
        params,
        recipeId,
        tone,
        numberOfResults
      );

      return { succes: true, results: createSuperpowerResults }; //should be {results : []}
    } catch (error) {
      // throw new Error(error);
      throw error;
    }
  }

  // saveCreationResult(creation: AIAbilityCreation) {
  //   let cleaned = cleanObject(creation);
  //   console.log("attempting save creation - ", cleaned);
  //   const doc = this.firestore.collection(DB_CREATIONS).doc();
  //   cleaned.id = doc.id;
  //   doc
  //     .set({ ...cleaned })
  //     .then((result) => {
  //       console.log("save creation success - ", result);
  //     })
  //     .catch((error) => {
  //       console.error("save creation error - ", error);
  //     });

  //   //save
  // }

  /**
   *
   * @param creation The creation to add a favorite event too
   * @param favorite True if we want to favorite, false if we want to unfavorite
   */
  async favoriteCreationResult(
    creation: CreationResult,
    favorite: boolean,
    callback: any
  ) {
    const user_id = firebase.auth().currentUser?.uid;

    //1. attempt fetch existing favorited event

    const existingEvent = await this.fetchCreationFavorite(creation);

    if (!existingEvent) {
      var event = new CreationFavoritedEvent();

      event.user_id = user_id!;
      event.creation_id = creation.id!;
      event.favorited = favorite;

      const now = new Date().getTime();
      event.created_on = now;
      event.last_updated = now;
      event.last_updated_by_user_id = user_id!;

      let cleaned = cleanObject(event);
      console.log("attempting save creation favorited event - ", cleaned);
      const doc = this.firestore.collection(DB_CREATION_FAVORITES).doc();
      cleaned.id = doc.id;
      doc
        .set({ ...cleaned })
        .then((result) => {
          console.log("save creation favorited event success - ", result);
          callback(true); //success
        })
        .catch((error) => {
          console.error("failed save creation favorited event error - ", error);
          callback(false); //failure
        });
    } else {
      const doc = this.firestore
        .collection(DB_CREATION_FAVORITES)
        .doc(existingEvent.id);

      doc
        .update({ favorited: favorite })
        .then((result) => {
          console.log("save creation favorited event success - ", result);
          callback(true); //success
        })
        .catch((error) => {
          console.error("failed save creation favorited event error - ", error);
          callback(false); //failure
        });
    }

    //save
  }

  async fetchCreationFavorite(creation: CreationResult) {
    const db = this.firestore.collection(DB_CREATION_FAVORITES);

    try {
      const snapshot = await db.where("creation_id", "==", creation.id).get();
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
      return snapshot.size > 0
        ? new CreationResult(snapshot.docs[0].data())
        : null;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return null;
    }
  }

  async fetchFavoritedCreationsForCurrentUser() {
    const db = this.firestore.collection(DB_CREATION_FAVORITES);

    try {
      let favorites: CreationFavoritedEvent[] = [];
      const user_id = firebase.auth().currentUser?.uid;
      const snapshot = await db.where("user_id", "==", user_id).get();
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        //only store if favorites is YES (true)
        let event = new CreationFavoritedEvent(doc.data());
        if (event.favorited === true) favorites.push(event);
      });
      return favorites;
    } catch (error) {
      console.log("Error getting documents: ", error);
      return null;
    }
  }
}
