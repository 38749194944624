/**
 * Code splitting Components
 * AsyncComponents
 */
import React from "react";
import Loadable from "react-loadable";
import { HulkPageLoader } from "components/GlobalComponents";

const default_async_page_loader_delay = 2500;
// Dasboard Urls
const AsyncFullPageUrlsComponent = Loadable({
  loader: () => import("routes/FullPageUrls"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
//  Dashboard 1
const AsyncDashboard1Component = Loadable({
  loader: () => import("routes/Dashboard/Dashboard1"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

// Dashboard 2
const AsyncDashboard2Component = Loadable({
  loader: () => import("routes/Dashboard/Dashboard2"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Dashboard 3
const AsyncDashboard3Component = Loadable({
  loader: () => import("routes/Dashboard/Dashboard3"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

// Dashboard 4
const AsyncDashboard4Component = Loadable({
  loader: () => import("routes/Dashboard/Dashboard4"),
  loading: () => <HulkPageLoader />,
  delay: 500,
});

// Shop Page
const AsyncShopComponent = Loadable({
  loader: () => import("routes/Ecommerce/Shop"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Product Detail Page
const AsyncProductDetailComponent = Loadable({
  loader: () => import("routes/Ecommerce/ProductDetail"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Cart Page
const AsyncCartComponent = Loadable({
  loader: () => import("routes/Ecommerce/Cart"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Checkout Page
const AsyncCheckoutComponent = Loadable({
  loader: () => import("routes/Ecommerce/Checkout"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Invoice Page
const AsyncInvoiceComponent = Loadable({
  loader: () => import("routes/Ecommerce/Invoice"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// SignIn Page
const AsyncSignInComponent = Loadable({
  loader: () => import("routes/Ecommerce/SignIn"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Email Page
const AsyncMailComponent = Loadable({
  loader: () => import("routes/Email"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

// Chat Page
const AsyncChatComponent = Loadable({
  loader: () => import("routes/Chat"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Blog Page
const AsyncBlogGridComponent = Loadable({
  loader: () => import("routes/Blog/BlogGrid"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Tables Page
const AsyncAgGridComponent = Loadable({
  loader: () => import("routes/Tables/AgGrid"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Basic Table
const AsyncBasicTableComponent = Loadable({
  loader: () => import("routes/Tables/BasicTable"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
//
// Search Table
const AsyncSearchTableComponent = Loadable({
  loader: () => import("routes/Tables/SearchTable"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Custom Table
const AsyncCustomTableComponent = Loadable({
  loader: () => import("routes/Tables/CustomTable"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Blog Detail Page
const AsyncBlogDetailComponent = Loadable({
  loader: () => import("routes/Blog/BlogDetail"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Standard Profile Page
const AsyncStandardComponent = Loadable({
  loader: () => import("routes/Pages/Profile/Standard"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Standard Profile Page
const AsyncCustomProfileComponent = Loadable({
  loader: () => import("routes/Pages/Profile/Custom"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Modern Feeds
const AsyncModernComponent = Loadable({
  loader: () => import("routes/Pages/Profile/Modern"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Contact Page
const AsyncContactGridComponent = Loadable({
  loader: () => import("routes/Pages/ContactGrid"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Faq Page
const AsyncFaqComponent = Loadable({
  loader: () => import("routes/Pages/Faq"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Pricing V1 Page
const AsyncPricingV1Component = Loadable({
  loader: () => import("routes/NewHome/Pricing"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// pricing V2 Page
const AsyncPricingV2Component = Loadable({
  loader: () => import("routes/Pages/Pricing/Style2"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Pricing Upgrade
const AsyncPricingUpgradeComponent = Loadable({
  loader: () => import("routes/Pages/Pricing/PricingUpgrade"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Vertical Timeline
const AsyncVerticalTimelineComponent = Loadable({
  loader: () => import("routes/Pages/Timeline/VerticalTimeline"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Horizontal Timeline
const AsyncHorizontalTimelineComponent = Loadable({
  loader: () => import("routes/Pages/Timeline/HorizontalTimeline"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Horizontal Stepper
const AsyncHorizontalStepperComponent = Loadable({
  loader: () => import("routes/Pages/Stepper/HorizontalStepper"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Vertical Stepper
const AsyncVerticalStepperComponent = Loadable({
  loader: () => import("routes/Pages/Pricing/PricingUpgrade"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Ui Components
const AsyncAppbarComponent = Loadable({
  loader: () => import("routes/UiComponents/AppBar"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncAvatarsComponent = Loadable({
  loader: () => import("routes/UiComponents/Avatars"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncButtonsComponent = Loadable({
  loader: () => import("routes/UiComponents/Buttons"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncBottomNavigationsComponent = Loadable({
  loader: () => import("routes/UiComponents/BottomNavigations"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncChipComponent = Loadable({
  loader: () => import("routes/UiComponents/Chip"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncListComponent = Loadable({
  loader: () => import("routes/UiComponents/List"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncModalsComponent = Loadable({
  loader: () => import("routes/UiComponents/Modals"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Calendar Page
const AsyncCalendarComponent = Loadable({
  loader: () => import("routes/BigCalendar"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// User Settings
const AsyncUserSettingsComponent = Loadable({
  loader: () => import("routes/UserSettings"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Vedio Player
const AsyncVideoPlayerComponent = Loadable({
  loader: () => import("routes/VideoPlayer"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Error Page 404
const AsyncErrorPage404Component = Loadable({
  loader: () => import("routes/Error/404"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
// Error Page 500
const AsyncErrorPage500Component = Loadable({
  loader: () => import("routes/Error/500"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncAbilitiesComponent = Loadable({
  loader: () => import("routes/NewSuperpowers"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncSuperheroesComponent = Loadable({
  loader: () => import("routes/Superheroes"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncEditorComponent = Loadable({
  loader: () => import("routes/Editor"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncCreateSequenceComponent = Loadable({
  loader: () => import("routes/Pages/Sequence"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncCreationsSequenceComponent = Loadable({
  loader: () => import("routes/Pages/Creations"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncMissionsComponent = Loadable({
  loader: () => import("routes/Pages/Missions"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});
const AsyncFullPageAboutUsUrlsComponent = Loadable({
  loader: () => import("routes/AboutUsUrls"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncDemosComponent = Loadable({
  loader: () => import("routes/Demos"),
  loading: () => <HulkPageLoader />,
  delay: 1000,
});

const AsyncScriptsComponent = Loadable({
  loader: () => import("routes/Pages/Scripts"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncGenerationsComponent = Loadable({
  loader: () => import("routes/Pages/Generations"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncScheduleComponent = Loadable({
  loader: () => import("routes/Calendar"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncPreviewComponent = Loadable({
  loader: () => import("routes/Pages/Preview"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

// const AsyncHomeComponent = Loadable({
//   loader: () => import("routes/Pages/Home"),
//   loading: () => <HulkPageLoader />,
//   delay: default_async_page_loader_delay,
// });

const AsyncHomeComponent = Loadable({
  loader: () => import("routes/NewHome"),
  loading: () => <div />,
  delay: default_async_page_loader_delay,
});

const AsyncCommunityComponent = Loadable({
  loader: () => import("routes/Community"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncAdminComponent = Loadable({
  loader: () => import("routes/Admin2"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

const AsyncReferralDashboardComponent = Loadable({
  loader: () => import("routes/ReferralDashboard"),
  loading: () => <HulkPageLoader />,
  delay: default_async_page_loader_delay,
});

export {
  AsyncFullPageUrlsComponent,
  AsyncDashboard1Component,
  AsyncDashboard2Component,
  AsyncDashboard3Component,
  AsyncDashboard4Component,
  AsyncShopComponent,
  AsyncProductDetailComponent,
  AsyncCartComponent,
  AsyncCheckoutComponent,
  AsyncSignInComponent,
  AsyncMailComponent,
  AsyncContactGridComponent,
  AsyncStandardComponent,
  AsyncCustomProfileComponent,
  AsyncModernComponent,
  AsyncChatComponent,
  AsyncBlogGridComponent,
  AsyncBlogDetailComponent,
  AsyncFaqComponent,
  AsyncPricingV1Component,
  AsyncPricingV2Component,
  AsyncAppbarComponent,
  AsyncAvatarsComponent,
  AsyncButtonsComponent,
  AsyncBottomNavigationsComponent,
  AsyncSearchTableComponent,
  AsyncCustomTableComponent,
  AsyncChipComponent,
  AsyncListComponent,
  AsyncModalsComponent,
  AsyncAgGridComponent,
  AsyncBasicTableComponent,
  AsyncPricingUpgradeComponent,
  AsyncVerticalTimelineComponent,
  AsyncHorizontalTimelineComponent,
  AsyncHorizontalStepperComponent,
  AsyncVerticalStepperComponent,
  AsyncInvoiceComponent,
  AsyncCalendarComponent,
  AsyncUserSettingsComponent,
  AsyncErrorPage404Component,
  AsyncErrorPage500Component,
  AsyncVideoPlayerComponent,
  AsyncAbilitiesComponent,
  AsyncEditorComponent,
  AsyncCreateSequenceComponent,
  AsyncCreationsSequenceComponent,
  AsyncMissionsComponent,
  AsyncFullPageAboutUsUrlsComponent,
  AsyncSuperheroesComponent,
  AsyncDemosComponent,
  AsyncScriptsComponent,
  AsyncScheduleComponent,
  AsyncPreviewComponent,
  AsyncGenerationsComponent,
  AsyncHomeComponent,
  AsyncCommunityComponent,
  AsyncAdminComponent,
  AsyncReferralDashboardComponent,
};
